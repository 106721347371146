import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { Navigate, Outlet, useNavigate } from "react-router";
import loadable from "@loadable/component";

import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import PageLayout from "layout/PageLayout";

import Student from "modules/Student";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setCompleteId, signout } from "modules/Auth/store";
import { users_role } from "@mindprint-learning/api-lib";
import Report from "modules/Student/Report";
import Trial from "modules/Trial";

const LoginPage = loadable(() => import("modules/Auth/LoginPage"));
const SaveSuccess = loadable(
  () => import("components/SaveSuccess/SaveSuccess")
);

export enum RouteLink {
  Home = "/home",
  Login = "/login",
  Settings = "/settings",
  SaveSuccess = "/save-success",
  // Assessments = '/assess',
  Reports = "/reports",
  Trial = "/trial",
  // Strategies = '/strategies',
}

// https://www.go.mindprintlearning.com/home/complete?id=e22aik00t8z9a

const PrivateRoute = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const user = useAppSelector((state: RootState) => state.auth.user);

  // CNB sometimes sends 'Id' (resume > complete) and sometimes 'id' (reg complete)
  const completeId = searchParams.get("Id") || searchParams.get("id");

  useEffect(() => {
    if (completeId) {
      dispatch(setCompleteId(completeId));
    }
  }, [completeId, dispatch]);

  return user ? (
    <Outlet />
  ) : (
    <Navigate
      replace
      to={RouteLink.Login}
      state={{ path: location.pathname }}
    />
  );
};

const AppRoutes = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const token = useAppSelector((state: RootState) => state.auth.token);
  const assessments = useAppSelector(
    (state: RootState) => state.student.assessments
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    const deleteCookie = (name: string) => {
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.mindprintlearning.com;`;
    };
    if (assessments) {
      const cookiesToRemove = "WebCNP";
      const currentAssessment = assessments[0]?.subid;

      const cookieValue = document.cookie
        .split("; ")
        .find((cookie) => cookie.startsWith(`${cookiesToRemove}=`))
        ?.split("=")[1];

      if (cookieValue && !cookieValue.includes(currentAssessment)) {
        // Check if the cookie value is present and does not include the current assessment
        deleteCookie(cookiesToRemove);
      }
    }
  }, [assessments]);

  useEffect(() => {
    if (
      user?.role === users_role.teacher ||
      user?.role === users_role.site_admin ||
      user?.role === users_role.org_admin ||
      user?.role === users_role.god_admin
    ) {
      dispatch(signout());
      window.location.replace(
        `${process.env.REACT_APP_TEACHER_URL}login?t=${token}`
      );
    }
  }, [user, dispatch, token]);

  const navigate = useNavigate();
  useEffect(() => {
    const val = window.location.href.split(".")[0].indexOf("trial");

    if (val > -1) {
      navigate("trial");
    }
  }, [navigate]);

  return (
    <Routes>
      <Route
        path={""}
        element={
          <PageLayout>
            <PrivateRoute />
          </PageLayout>
        }
      >
        <Route path="/*" element={<Navigate to="/home" />} />

        <Route
          path={`${RouteLink.Home}/*`}
          element={<Student firstName={user?.first_name} userId={user?.id} />}
        />
        <Route path={RouteLink.Settings} element={<div>My Settings</div>} />

        <Route path={`${RouteLink.Reports}/:subid`} element={<Report />} />
      </Route>
      <Route path={`${RouteLink.Login}/*`} element={<LoginPage />} />
      <Route path={`${RouteLink.SaveSuccess}/*`} element={<SaveSuccess />} />
      <Route path={`${RouteLink.Trial}/*`} element={<Trial />} />
    </Routes>
  );
};

export default AppRoutes;

// Prototyping...

// const Assessments = () => {
//   return (
//     <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
//       <div style={{ display: 'flex', gap: '1em', flexDirection: 'column' }}>
//         <h1>New Assessment</h1>
//         <Button
//           label="start/resume"
//           onClick={() => window.open('http://localhost:3001/', '_blank')}
//         />
//       </div>
//       <div>
//         <h1>Completed Assessments</h1>
//         <div>here</div>
//       </div>
//     </div>
//   );
// };

// const Reports = () => {
//   return (
//     <>
//       <div>
//         <h1>Reports</h1>
//         <MasonryContainer
//           items={[<Card>One</Card>, <Card>Two</Card>, <Card>Three</Card>]}
//         />
//       </div>
//     </>
//   );
// };

// const Strategies = () => {
//   return (
//     <div>
//       <h1>Strategies</h1>
//       <MasonryContainer
//         items={[<Card>One</Card>, <Card>Two</Card>, <Card>Three</Card>]}
//       />
//     </div>
//   );
// };

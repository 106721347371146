import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { TrialCreate, TrialState } from "./types";
import api from "services/api";

const initialState: TrialState = {
  data: undefined,
  checkingCode: false,
  showError: false,
  trialCreated: false,
  mindprintProfile: undefined,
  mindprintProfileLoading: false,
  studentProfile: undefined,
  studentProfileLoading: false,
};

export const getTrialData = createAsyncThunk(
  "trial/getTrialData",
  async (code: string) => {
    const response = await api.getTrialData(code);

    return response.data;
  }
);

export const createTrial = createAsyncThunk(
  "trial/createTrial",
  async (data: TrialCreate) => {
    const response = await api.createTrial(data);

    return response.data;
  }
);

export const completeTrial = createAsyncThunk(
  "trial/completeTrial",
  async (subid: string) => {
    const response = await api.completeTrial(subid);

    return response.data;
  }
);

export const getTrialStudentProfile2 = createAsyncThunk(
  "trial/getTrialStudentProfile2",
  async (subid: string) => {
    const response = await api.getTrialStudentProfile2(subid);

    return response.data;
  }
);

export const trialSlice = createSlice({
  name: "trial",
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
    hideError: (state) => {
      state.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTrialData.fulfilled, (state, action) => {
      state.data = action.payload;
      state.checkingCode = false;
    });
    builder.addCase(getTrialData.rejected, (state) => {
      state.data = undefined;
      state.checkingCode = false;
      state.showError = true;
    });
    builder.addCase(getTrialData.pending, (state) => {
      state.checkingCode = true;
      state.showError = false;
      state.data = undefined;
    });
    builder.addCase(createTrial.fulfilled, (state, action) => {
      state.trialCreated = true;
    });
    builder.addCase(createTrial.rejected, (state) => {
      state.trialCreated = false;
      alert("Error");
    });
    builder.addCase(createTrial.pending, (state) => {
      state.trialCreated = false;
    });
    builder.addCase(completeTrial.fulfilled, (state, action) => {
      state.mindprintProfile = action.payload;
      state.mindprintProfileLoading = false;
    });
    builder.addCase(completeTrial.rejected, (state) => {
      state.mindprintProfile = undefined;
      state.mindprintProfileLoading = false;
    });
    builder.addCase(completeTrial.pending, (state) => {
      state.mindprintProfile = undefined;
      state.mindprintProfileLoading = true;
    });
    builder.addCase(getTrialStudentProfile2.fulfilled, (state, action) => {
      state.studentProfile = action.payload;
      state.studentProfileLoading = false;
    });
    builder.addCase(getTrialStudentProfile2.rejected, (state) => {
      state.studentProfile = undefined;
      state.studentProfileLoading = false;
    });
    builder.addCase(getTrialStudentProfile2.pending, (state) => {
      state.studentProfile = undefined;
      state.studentProfileLoading = true;
    });
  },
});

export const clear = trialSlice.actions.clear;
export const hideError = trialSlice.actions.hideError;

export default trialSlice.reducer;

import { useMemo } from "react";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { Button, ButtonTypes, Modal } from "@mindprint-learning/design";

import styles from "./LaunchAssessment.module.css";

const LaunchAssessment = ({
  url,
  buttonLabel,
  handleClose,
}: LaunchAssessmentProps) => {
  const ageValidation = (url: string | undefined) => {
    if (url) {
      const searchParams = new URL(url).searchParams;
      const age = searchParams.get("age");

      if (age && parseInt(age) > 7) {
        return true;
      }
      return false;
    }
    return false;
  };
  const cachedAgeValidation = useMemo(() => ageValidation(url), [url]);

  if (!cachedAgeValidation) {
    return (
      <Modal
        show={url !== undefined}
        handleClose={handleClose}
        title="Age Verification"
      >
        <div style={{ maxWidth: 400, marginBottom: "1em" }}>
          <p>
            Sorry, it seems you are under age 8 and too young to take MindPrint.
            Please share this message with an adult to help you.
          </p>
        </div>
        <div
          style={{ display: "flex", gap: "0.5em", justifyContent: "flex-end" }}
        >
          <Button label="Ok" onClick={handleClose} primary={true} />
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      show={url !== undefined}
      handleClose={handleClose}
      title="Launch Assessment"
    >
      <div style={{ maxWidth: 400, marginBottom: "1em" }}>
        <p>You are about to {buttonLabel} your MindPrint Assessment.</p>
        <p>
          Please make sure you're in a quiet place and using a desktop or laptop
          computer <em>(no touchscreens).</em>
        </p>
      </div>
      <div
        style={{ display: "flex", gap: "0.5em", justifyContent: "flex-end" }}
      >
        <Button label="Cancel" onClick={handleClose} primary={false} />
        <Button
          label={
            <span className={styles.iconText}>
              <FaArrowUpRightFromSquare />{" "}
              <p>
                {buttonLabel &&
                  `${buttonLabel[0].toUpperCase()}${buttonLabel.slice(
                    1,
                    buttonLabel.length
                  )} Assessment`}
              </p>
            </span>
          }
          onClick={() => {
            window.open(url, "_self");
            handleClose();
          }}
          primary={true}
          type={ButtonTypes.neutral}
        />
      </div>
    </Modal>
  );
};

export default LaunchAssessment;

interface LaunchAssessmentProps {
  url: string | undefined;
  buttonLabel: string | undefined;
  handleClose: () => void;
}

/*

- Quitting the interstitial screens needs to go to v2 (might need a whole new v2 survey after all)
- Add numbers in progress bar
- Make sure that 3001 goes to 3000 (too) (teacher logs into student, should redirect back to admin)
- "in 2 hours" for cnb timestamps (assessments table)
- Section/class to the assessments tables
- bug: "ok" in breadcrumb for "Manage"
-

*/

import {
  Button,
  ButtonTypes,
  Container,
  Divider,
  Input,
  InputTypes,
  MindPrintProfile,
  Modal,
  ModalTypes,
  NavigationBar,
  Page,
  SimpleLoader,
  StudentReport,
} from "@mindprint-learning/design";
import { ReactNode, useEffect, useState } from "react";
import { Route, Routes } from "react-router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  clear,
  completeTrial,
  getTrialStudentProfile2,
  createTrial,
  getTrialData,
  hideError,
} from "./store";
import { validEmail } from "utils/text";
import { RootState } from "store/store";
import { useSearchParams } from "react-router-dom";

const Trial = () => {
  const dispatch = useAppDispatch();
  const showError = useAppSelector((state: RootState) => state.trial.showError);

  return (
    <Page>
      <NavigationBar
        links={[[]]}
        ctaContainer={
          <Button
            label="About MindPrint"
            onClick={() => {
              window.open("https://www.mindprintlearning.com/", "_blank");
            }}
            primary={false}
            type={ButtonTypes.cta}
          />
        }
      />
      <Modal
        type={ModalTypes.warning}
        show={showError}
        handleClose={() => dispatch(hideError())}
        title="Oops!"
      >
        <div>The trial code you entered is incorrect. Please try again.</div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button label="Ok" onClick={() => dispatch(hideError())} />
        </div>
      </Modal>
      <Container>
        <div style={{ textAlign: "center" }}>
          <h1>MindPrint Adult Trial</h1>
        </div>
        <Divider />

        <Routes>
          <Route path="*" element={<Create />} />
          {/* can't change the url here because it will break every trial email already sent out */}
          <Route path="complete" element={<Complete />} />
          <Route path="complete-student" element={<CompleteStudent />} />{" "}
        </Routes>
      </Container>
    </Page>
  );
};

export default Trial;

const Create = () => {
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState("");

  const data = useAppSelector((state: RootState) => state.trial.data);
  const trialCreated = useAppSelector(
    (state: RootState) => state.trial.trialCreated
  );

  const clearPreviousSession = () => {
    const deleteCookie = (name: string) => {
      document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.mindprintlearning.com;`;
    };
    const cookiesToRemove = "WebCNP";
    const cookieValue = document.cookie
      .split("; ")
      .find((cookie) => cookie.startsWith(`${cookiesToRemove}=`))
      ?.split("=")[1];

    if (cookieValue) {
      // If there is a WebCNP cookie, delete it. All trials should start with a fresh assessment.
      console.log("Starting fresh session");
      deleteCookie(cookiesToRemove);
    }
  };

  const dispatch = useAppDispatch();

  const updateEmail = (value: string) => {
    if (emailError) {
      setEmailError("");
    }
    setEmailValid(validEmail(value));
    setEmail(value);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {trialCreated ? (
        <MainContainer>
          <div
            style={{
              fontStyle: "italic",
              fontSize: "1.5em",
              marginBottom: "1em",
              textAlign: "center",
            }}
          >
            You will need a desktop or laptop computer,
            <br />
            NOT A TOUCHSCREEN OR TABLET.
          </div>
          <div style={{ fontStyle: "italic", marginBottom: "1em" }}>
            Please reserve 1 hour of uninterrupted time to complete the full
            assessment.
          </div>
          <Button
            label="Begin Assessment"
            onClick={() => {
              if (data) {
                window.location.href = data.url;
              }
            }}
            primary={false}
          />
        </MainContainer>
      ) : (
        <>
          {data === undefined ? (
            <div
              style={{
                display: "flex",
                gap: "0.5em",
                flexDirection: "column",
              }}
            >
              <MainContainer>
                <>
                  <h2 style={{ marginBottom: "1em" }}>Sign In</h2>
                  <div style={{ marginBottom: "0.5em" }}>
                    Please enter the trial code you received to get started
                  </div>
                  <div
                    style={{
                      fontSize: "1.5em",
                      maxWidth: 300,
                      marginBottom: "1em",
                    }}
                  >
                    <Input
                      id="code-value"
                      value={code}
                      onChange={(value) => setCode(value)}
                      valid={code.length > 0}
                    />
                  </div>
                  <Button
                    label="Submit"
                    onClick={() => {
                      dispatch(getTrialData(code));
                    }}
                    primary={false}
                    disabled={code.length === 0}
                  />
                </>
              </MainContainer>
            </div>
          ) : (
            <div
              style={{ display: "flex", gap: "0.5em", flexDirection: "column" }}
            >
              <MainContainer>
                <>
                  <h2>Enter Your Email</h2>
                  <Divider />
                  <div style={{ marginBottom: "0.5em" }}>
                    Your confidential results will be sent to the email address
                    you provide.
                  </div>
                  <div
                    style={{
                      fontSize: "1.5em",
                      maxWidth: 800,
                    }}
                  >
                    <Input
                      id="email-value"
                      value={email}
                      onChange={(value) => updateEmail(value)}
                      type={InputTypes.email}
                      valid={emailValid}
                      errorMessage={emailError}
                    />
                  </div>
                  <Button
                    label="Continue"
                    onClick={() => {
                      if (emailValid) {
                        clearPreviousSession();
                        dispatch(
                          createTrial({
                            subid: data.subid,
                            email: email,
                            trial_code_id: data.trial_code_id,
                          })
                        );
                      } else
                        setEmailError(
                          "Please enter a valid email to continue."
                        );
                    }}
                    primary={false}
                  />
                </>
              </MainContainer>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const MainContainer = ({ children }: MainContainerProps) => {
  return (
    <div
      style={{
        padding: "2em 3em",
        backgroundColor: "#fafafa",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5em",
        borderRadius: "0.5em",
        maxWidth: 700,
      }}
    >
      {children}
    </div>
  );
};

interface MainContainerProps {
  children: ReactNode;
}

const Complete = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  // CNB sometimes sends 'Id' (resume > complete) and sometimes 'id' (reg complete)
  const completeId = searchParams.get("Id") || searchParams.get("id");
  const profile = useAppSelector(
    (state: RootState) => state.trial.mindprintProfile
  );
  const profileLoading = useAppSelector(
    (state: RootState) => state.trial.mindprintProfileLoading
  );

  useEffect(() => {
    if (completeId && profile === undefined && profileLoading === false) {
      if (completeId !== undefined) {
        dispatch(completeTrial(completeId));
      }
    }
  }, [completeId, dispatch, profileLoading, profile]);

  return (
    <div>
      {profileLoading ? (
        <SimpleLoader />
      ) : (
        <>
          {profile ? (
            <MindPrintProfile data={profile} trial={true} />
          ) : (
            <div>profile not found</div>
          )}
        </>
      )}
    </div>
  );
};

const CompleteStudent = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  // CNB sometimes sends 'Id' (resume > complete) and sometimes 'id' (reg complete)
  const completeId = searchParams.get("Id") || searchParams.get("id");
  const profile = useAppSelector(
    (state: RootState) => state.trial.studentProfile
  );
  const profileLoading = useAppSelector(
    (state: RootState) => state.trial.studentProfileLoading
  );

  useEffect(() => {
    if (completeId && profile === undefined && profileLoading === false) {
      if (completeId !== undefined) {
        dispatch(getTrialStudentProfile2(completeId));
      }
    }
  }, [completeId, dispatch, profileLoading, profile]);

  return (
    <div>
      {profileLoading ? (
        <SimpleLoader />
      ) : (
        <>
          {profile ? (
            <StudentReport report={profile.report} />
          ) : (
            <div>profile not found</div>
          )}
        </>
      )}
    </div>
  );
};

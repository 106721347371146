import { useEffect, useState } from "react";

import { Container } from "@mindprint-learning/design";
// import { useInView } from 'react-intersection-observer';

import styles from "./Banner.module.css";
import { Assessment } from "@mindprint-learning/api-lib";

const Banner = ({ firstName, lastAssessment, ctaClick }: BannerProps) => {
  const [message, setMessage] = useState(
    'You have been assigned a new assessment. Begin by clicking "Start Assessment" below.'
  );
  // const [buttonShowing, setButtonShowing] = useState(false);

  const getMessage = (lastAssessment: any) => {
    if (lastAssessment === undefined) {
      return 'You have been assigned a new assessment. Begin by clicking "Start Assessment" below.';
    } else {
      if (lastAssessment?.progress === 0) {
        return 'You have been assigned a new assessment. Begin by clicking "Start Assessment" below.';
      } else if (lastAssessment?.progress < 1) {
        return 'You have an assessment in progress. Click "Resume Assessment" below to finish.';
      } else if (lastAssessment?.completed_at !== null) {
        return "You have completed your assigned assessments. When your results are ready, they will show up in your Reports table below.";
      }
    }

    return 'You have been assigned a new assessment. Begin by clicking "Start Assessment" below.';
  };

  useEffect(() => {
    setMessage(getMessage(lastAssessment));
  }, [lastAssessment]);

  return (
    <>
      <header className={styles.main}>
        <svg
          className={styles.background}
          viewBox="0 0 1920 600"
          preserveAspectRatio="none"
        >
          <path
            d="M400 598.9l1520-152.8v164.4H0v-288z"
            className={styles.clip}
          />
          <path d="M923.4 546.3l996.6-97.4V1.5h-851z" className={styles.fill} />
        </svg>
        <img
          src="../images/student-profile-banner-2.png"
          className={styles.scene}
          alt=""
        />
        <div className={styles.reportInfo}>
          <Container>
            <div className={styles.reportText}>
              <h1 className={styles.welcomeMessage}>Hey, {firstName}!</h1>
              <div style={{ marginBottom: "1em" }}>{message}</div>
            </div>
          </Container>
        </div>
      </header>
    </>
  );
};

export default Banner;

interface BannerProps {
  firstName: string | null | undefined;
  lastAssessment: Assessment | undefined;
  ctaClick: () => void;
}

// const InViewComponent = ({ children, onChange }: InViewComponentProps) => {
//   const { ref, inView } = useInView({
//     threshold: [0.5],
//     rootMargin: '-80px 0px 0px 0px',
//   });

//   useEffect(() => {
//     onChange(inView);
//   }, [onChange, inView]);

//   return <div ref={ref}>{children}</div>;
// };

// interface InViewComponentProps {
//   children: ReactNode;
//   onChange: (inView: boolean) => void;
// }

/*

{ctaLabel && (
  <InViewComponent onChange={(value) => setButtonShowing(value)}>
    <Button
      type={ButtonTypes.cta}
      size={ButtonSizes.medium}
      primary={true}
      label={ctaLabel}
      onClick={ctaClick}
    />
  </InViewComponent>
)}

  {!buttonShowing && (
  <div className={styles.stickyButton}>
    <div onClick={ctaClick}>{ctaLabel}</div>
  </div>
)}
*/

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AssessmentPost } from "@mindprint-learning/api-lib";

import api from "services/api";
import { StudentState } from "./types";
import { CompleteAssessment, FeedbackProps } from "../Student.types";

const initialState: StudentState = {
  loading: false,
  hasError: false,
  assessments: undefined,
  assessmentUrl: undefined,
  reports: undefined,
  reportData: undefined,
};

export const fetchAllAssessments = createAsyncThunk(
  "student/assessments",
  async () => {
    const response = await api.getAssessments();
    return response.data;
  }
);

export const fetchOneAssessmentForHash = createAsyncThunk(
  "student/assessments/hash",
  async (hash: string) => {
    const response = await api.getUrlForHash(hash);
    return response.data;
  }
);

export const fetchOneReportForHash = createAsyncThunk(
  "student/reports/hash",
  async (hash: string) => {
    const response = await api.getProfileForHash(hash);
    return response.data;
  }
);

// create assessment (auto-create setting)
export const createAssessment = createAsyncThunk(
  "userDetail/createAssessment",
  async ({ user_id, type }: AssessmentPost) => {
    const data = {
      user_id: user_id,
      type: type,
    };
    return (await api.createAssessment(data)).data;
  }
);

export const complete = createAsyncThunk(
  "student/assessments/complete",
  async (subid: CompleteAssessment) => {
    const response = await api.complete(subid);
    return response.data;
  }
);

export const sendFeedback = createAsyncThunk(
  "student/assessment/feedback",
  async ({ feedback, assessmentId }: FeedbackProps) => {
    return (await api.feedback(feedback, assessmentId)).data;
  }
);

export const checkCompleted = createAsyncThunk(
  "student/assessments/checkCompleted",
  async (userId: number) => {
    const response = await api.checkCompleted(userId);
    return response.data;
  }
);

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    clear: () => {
      return initialState;
    },
    clearAssessmentUrl: (state) => {
      const newState = { ...state };
      newState.assessmentUrl = undefined;
      return newState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAssessments.fulfilled, (state, action) => {
        state.assessments = action.payload.data;
      })
      .addCase(complete.pending, (state) => {
        state.loading = true;
      })
      .addCase(complete.fulfilled, (state, action) => {
        state.loading = false;
        state.assessments = [{ ...action.payload, progress: 1 }];
      })
      .addCase(complete.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(sendFeedback.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendFeedback.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendFeedback.rejected, (state) => {
        state.loading = false;
        state.hasError = true;
      })
      .addCase(fetchOneAssessmentForHash.fulfilled, (state, action) => {
        state.assessmentUrl = action.payload.data;
      })
      .addCase(fetchOneReportForHash.fulfilled, (state, action) => {
        state.reportData = action.payload;
      })
      .addCase(createAssessment.fulfilled, (state, action) => {
        if (state.assessments) {
          state.assessments = [...state.assessments, action.payload];
        } else {
          state.assessments = [action.payload];
        }
      });
  },
});

export const clear = studentSlice.actions.clear;
export const clearAssessmentUrl = studentSlice.actions.clearAssessmentUrl;

export default studentSlice.reducer;

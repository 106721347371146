import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import {
  Assessment,
  AssessmentPost,
  AssessmentsResponse,
  MindPrintProfileReport,
} from "@mindprint-learning/api-lib";
import { StudentReportProps } from "@mindprint-learning/design";

import { EdlinkPost } from "modules/Auth/store";

// Types
import { Credentials, AuthResult } from "modules/Auth/store/types";
import { TrialCreate, TrialData } from "modules/Trial/store/types";
import { CompleteAssessment } from "modules/Student/Student.types";

const createAxiosInstance = (baseURL: string, timeout: number) => {
  const instance: AxiosInstance = axios.create({
    baseURL,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const msg = error.response?.data?.msg || "Failed to connect to server";
      console.error(msg);
    }
  );

  // see: https://stackoverflow.com/questions/47715451/localstorage-item-not-updating-in-axios-headers
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");

      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        } as AxiosRequestHeaders;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  return instance;
};

const gateway = createAxiosInstance(
  process.env.REACT_APP_API_GATEWAY_URL || "",
  3000
);

const api = createAxiosInstance(process.env.REACT_APP_API_URL || "", 3000);

const apiService = {
  verify: (token?: string) => gateway.get<AuthResult>("auth/verify"),

  signin: (credentials: Credentials) =>
    gateway.post<AuthResult>("auth/signin", credentials),

  edlinkSignin: (data: EdlinkPost) =>
    gateway.post<AuthResult>("auth/sso", {
      code: data.code,
      type: data.type,
    }),

  resetPassword: (email: string) =>
    gateway.post("auth/reset-password", { email }),

  getAssessments: () => api.get<AssessmentsResponse>("assessments"),

  getReports: () => api.get<any>("reports"),

  getUrlForHash: (hash: string) => api.get(`assessments/${hash}`),

  getProfileForHash: (hash: string) =>
    api.get(`reports/learner-profile/2/${hash}`),

  checkCompleted: (userId: number) =>
    api.get(`assessments/users/${userId}/check-completed`),

  complete: (subid: CompleteAssessment) =>
    api.post(`assessments/complete`, subid),

  feedback: (feedback: string, assessmentId: number | undefined) => {
    const data = {
      feedback: feedback,
    };

    // adding custom header for new api structure
    return api.put(`assessments/${assessmentId}/feedback`, data, {
      headers: {
        "x-new-assessment-flow": true,
      },
    });
  },

  createAssessment: (data: AssessmentPost) => {
    return api.post<Assessment>("/assessments", data);
  },

  getTrialData: (code: string) =>
    api.get<TrialData | undefined>(`trial/${code}`),

  createTrial: (data: TrialCreate) => api.post(`trial/${data.subid}`, data),

  completeTrial: (subid: string) =>
    api.put<MindPrintProfileReport | undefined>(`trial/${subid}`),

  getTrialStudentProfile2: (subid: string) =>
    api.get<StudentReportProps | undefined>(`trial/${subid}/student-profile`),
};
export default apiService;

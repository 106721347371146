import React from "react";

import { Page } from "@mindprint-learning/design";
import Header from "./Header";

interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <Page>
      <>
        <Header />
        {children}
      </>
    </Page>
  );
};

export default PageLayout;

import { useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import { User, users_role } from "@mindprint-learning/api-lib";
import {
  NavigationBar,
  Button,
  ButtonSizes,
  ButtonTypes,
  Tooltip,
  TooltipDirection,
} from "@mindprint-learning/design";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { signout } from "modules/Auth/store";

import styles from "./Header.module.css";
import UserProfileCircle from "components/UserProfileCircle";

const Header = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  const [showTooltip, setShowTooltip] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const goToLogin = () => {
    navigate("/login");
  };

  const handleLogout = () => {
    setShowTooltip(false);
    dispatch(signout());

    navigate("/login");
  };

  const studentLinks = [[]];

  return (
    <>
      <NavigationBar
        activePath={location.pathname}
        ctaContainer={
          <>
            {user ? (
              <Tooltip
                direction={TooltipDirection.bottom}
                show={showTooltip}
                close={() => setShowTooltip(false)}
                offsetHorizontal={120}
                content={<ProfileMenu logout={handleLogout} user={user} />}
              >
                <UserProfileCircle
                  user={user}
                  size={50}
                  onClick={() => setShowTooltip(true)}
                />
              </Tooltip>
            ) : (
              <Button
                label="Sign In"
                size={ButtonSizes.small}
                type={ButtonTypes.cta}
                onClick={goToLogin}
              />
            )}
          </>
        }
        links={studentLinks}
      />
    </>
  );
};

export default Header;

// const AdminHeader = ({
//   showTooltip,
//   setShowTooltip,
//   goToLogin,
// }: AdminHeaderProps) => {
//   const user = useAppSelector((state: RootState) => state.auth.user);

//   const location = useLocation();

//   return (
//     <NavigationBar
//       activePath={location.pathname}
//       ctaContainer={
//         <>
//           {user ? (
//             <Tooltip
//               direction={TooltipDirection.bottom}
//               show={showTooltip}
//               close={() => setShowTooltip(false)}
//               offsetRight={120}
//               content={
//                 <ProfileMenu
//                   logout={handleLogout}
//                   settings={goToSettings}
//                   user={user}
//                 />
//               }
//             >
//               <UserProfileCircle
//                 user={user}
//                 size={50}
//                 onClick={() => setShowTooltip(true)}
//               />
//             </Tooltip>
//           ) : (
//             <Button
//               label="sign in"
//               size={ButtonSizes.small}
//               type={ButtonTypes.cta}
//               onClick={goToLogin}
//             />
//           )}
//         </>
//       }
//       links={[
//         [
//           <Link
//             component={RouterLink}
//             to={`${RouteLink.Manage}`}
//             type={LinkTypes.nav}
//           >
//             Manage
//           </Link>,
//           <Link
//             component={RouterLink}
//             to={`${RouteLink.Assess}/types`}
//             type={LinkTypes.nav}
//           >
//             Assess
//           </Link>,
//           <Link
//             component={RouterLink}
//             to={`${RouteLink.Reports}/types`}
//             type={LinkTypes.nav}
//           >
//             Reports
//           </Link>,
//           <Link
//             component={RouterLink}
//             to={RouteLink.Strategies}
//             type={LinkTypes.nav}
//           >
//             Strategies
//           </Link>,
//         ],
//       ]}
//     />
//   );
// };

// interface AdminHeaderProps {
//   showTooltip: boolean;
//   setShowTooltip: (value: boolean) => void;
//   goToLogin: () => void;
//   handleLogout: () => void;
//   goToSettings: () => void;
// }

interface ProfileMenuProps {
  logout: () => void;
  user: User;
}
const ProfileMenu = ({ logout, user }: ProfileMenuProps) => {
  return (
    <div className={styles.profileMenu}>
      <UserProfileCircle user={user} size={200} />
      <div>
        {user.first_name} {user.last_name}
      </div>
      <div className={styles.buttonMenu}>
        <Button
          label="Log Out"
          size={ButtonSizes.small}
          type={ButtonTypes.cta}
          primary={true}
          onClick={logout}
        />
      </div>
    </div>
  );
};

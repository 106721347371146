import { StudentReport } from "@mindprint-learning/design";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import { clear, fetchOneReportForHash } from "./store";

const Report = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!params || !params.subid) return;
    dispatch(fetchOneReportForHash(params.subid));
    return () => {
      dispatch(clear());
    };
  }, [dispatch, params]);

  const reportData = useAppSelector(
    (state: RootState) => state.student.reportData
  );

  return (
    <>
      {reportData ? (
        <StudentReport report={reportData.report} />
      ) : (
        <div>loading</div>
      )}
    </>
  );
};
export default Report;

import { useEffect } from "react";
import { BrowserRouter, useSearchParams } from "react-router-dom";

// import PageLayout from 'layout/PageLayout';

import { useAppSelector, useAppDispatch } from "store/hooks";
import { RootState } from "store/store";
import { verify } from "modules/Auth/store";

import Routes from "./Routes";
import { SimpleLoader } from "@mindprint-learning/design";

const AppWrapper = () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};

export default AppWrapper;

const App = () => {
  const loading = useAppSelector((state: RootState) => state.auth.loading);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("t");

  if (token) {
    localStorage.setItem("token", token);
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(verify());
  }, [dispatch]);

  return <>{loading ? <SimpleLoader /> : <Routes />}</>;
};

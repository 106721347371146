import {
  Button,
  ButtonTypes,
  Label,
  Modal,
  ModalTypes,
  TextArea,
} from "@mindprint-learning/design";
import { useState } from "react";

const CompleteAssessment = ({ show, handleClose }: LaunchAssessmentProps) => {
  const [comments, setComments] = useState("");

  return (
    <>
      <Modal
        show={show}
        handleClose={() => {
          handleClose(comments);
        }}
        title="Congratulations!"
        type={ModalTypes.celebrate}
      >
        <div style={{ maxWidth: 400, marginBottom: "1em" }}>
          <div style={{ marginBottom: "1em" }}>
            You successfully completed your assessment.
          </div>
          <div style={{ marginBottom: "1em" }}>
            If you experienced a technical difficulty or interruption, please
            let us know in a comment below.
          </div>

          <div>
            <Label htmlFor="completeAssessment_textArea_comments">
              Comments
            </Label>
            <TextArea
              id="completeAssessment_textArea_comments"
              value={comments}
              onChange={(value: string) => setComments(value)}
            />
          </div>
        </div>
        <div
          style={{ display: "flex", gap: "0.5em", justifyContent: "flex-end" }}
        >
          <Button
            label={<>Submit</>}
            onClick={() => {
              handleClose(comments);
            }}
            primary={true}
            type={ButtonTypes.neutral}
          />
        </div>
      </Modal>
    </>
  );
};

export default CompleteAssessment;

interface LaunchAssessmentProps {
  show: boolean;
  handleClose: (comments: string) => void;
}

// redirect url = https://redirectmeto.com/http://localhost:3001/home/complete

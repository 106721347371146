import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { AuthState, Credentials } from "./types";
import api from "services/api";
import { users_role } from "@mindprint-learning/api-lib";

const initialState: AuthState = {
  user: null,
  token: null,
  loading: false,
  checked: false,
  completeId: undefined,
};

export const signin = createAsyncThunk(
  "auth/signin",
  async (credentials: Credentials) => {
    const response = await api.signin(credentials);

    return response.data;
  }
);

export interface EdlinkPost {
  code: string;
  type: string;
}
export const edlinkSignin = createAsyncThunk(
  "auth/edlink/signin",
  async ({ code, type }: EdlinkPost) => {
    const response = await api.edlinkSignin({ code, type });

    return response.data;
  }
);

export const verify = createAsyncThunk("auth/verify", async () => {
  const response = await api.verify();

  return response.data;
});

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (email: string) => {
    const response = await api.resetPassword(email);

    return response.data;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signout: (state) => {
      localStorage.removeItem("token");
      state.user = initialState.user;
      state.checked = true;
      state.loading = false;
    },
    setCompleteId: (state, action) => {
      state.completeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signin.pending, (state) => {
        state.loading = true;
      })
      .addCase(signin.fulfilled, (state, action) => {
        state.loading = false;
        const { token, user } = action.payload;

        state.user = user;
        state.token = token;
        localStorage.setItem("token", token);
      })
      .addCase(edlinkSignin.fulfilled, (state, action) => {
        state.loading = false;
        const { token, user } = action.payload;
        state.user = user;
        state.token = token;
        localStorage.setItem("token", token);
      })
      .addCase(edlinkSignin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(signin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(verify.pending, (state) => {
        state.loading = true;
        state.checked = false;
      })
      .addCase(verify.fulfilled, (state, action) => {
        state.user = action.payload.user;
        console.log("verified");
        state.loading = false;
        state.checked = true;
      })
      .addCase(verify.rejected, (state) => {
        console.log("not verified");
        state.loading = false;
        state.checked = true;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const signout = authSlice.actions.signout;
export const setCompleteId = authSlice.actions.setCompleteId;

export default authSlice.reducer;

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import * as SentryBrowser from "@sentry/browser";
import "./index.css";
import App from "./App";
import { store } from "store/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: "https://208e5317581d4e7683f33127ef0dc1f3@o4504180935557120.ingest.sentry.io/4504318333157376",
  integrations: [new SentryBrowser.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { Assessment, assessments_type } from "@mindprint-learning/api-lib";
import {
  Button,
  ButtonSizes,
  ButtonTypes,
  CellTypes,
  Container,
  Divider,
  Table,
  TableThemes,
  Toast,
  ToastTypes,
} from "@mindprint-learning/design";

// React imports
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RouteLink } from "Routes";

// Store imports
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/store";
import {
  clear,
  clearAssessmentUrl,
  complete,
  createAssessment,
  fetchAllAssessments,
  fetchOneAssessmentForHash,
  sendFeedback,
} from "./store";

// Component imports
import CompleteAssessment from "./CompleteAssessment";
import LaunchAssessment from "./LaunchAssessment";
import Banner from "components/Banner";

import { StudentProps } from "./Student.types";

const Student = ({ userId, firstName }: StudentProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Store Selectors
  const completeId = useAppSelector(
    (state: RootState) => state.auth.completeId
  );
  const assessments = useAppSelector(
    (state: RootState) => state.student.assessments
  );
  const assessmentUrl = useAppSelector(
    (state: RootState) => state.student.assessmentUrl
  );

  // Local State
  const [showComplete, setShowComplete] = useState(false);
  const [buttonLabel, setButtonLabel] = useState<string | undefined>();
  const [displayAssessments, setDisplayAssessments] = useState<
    Array<Assessment> | undefined
  >();
  const [lastAssessment, setLastAssessment] = useState<any | undefined>();
  const [displayReports, setDisplayReports] = useState<
    Array<any> | undefined
  >();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    dispatch(fetchAllAssessments());

    return () => {
      dispatch(clear());
    };
  }, [dispatch]);

  useEffect(() => {
    if (completeId) {
      // sets assessment as completed
      dispatch(complete({ subid: completeId }));
    }
  }, [completeId, dispatch]);

  useEffect(() => {
    if (completeId) {
      const show =
        location.pathname.search("/complete") !== -1 &&
        completeId !== undefined;

      setShowComplete(show);
    }
  }, [completeId, location]);

  // Assessments table
  useEffect(() => {
    if (!assessments) return;

    const val = assessments.map((assessment) => {
      let btnLabel = "Start Assessment";
      if (assessment?.completed_at !== null) {
        btnLabel = "View Report";
      } else if (assessment?.progress > 0) {
        btnLabel = "Resume Assessment";
      }
      return {
        ...assessment,
        created_at: assessment.created_at
          ? assessment.created_at
          : // @ts-ignore
            assessment.formatted.created_at,
        action: (
          <>
            {assessment.completed_at === null ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  label={btnLabel}
                  primary={true}
                  size={ButtonSizes.tiny}
                  type={ButtonTypes.cta}
                  onClick={() => {
                    if (assessment.hash)
                      dispatch(fetchOneAssessmentForHash(assessment.hash));
                  }}
                />
              </div>
            ) : (
              <>complete</>
            )}
          </>
        ),
        progressView: {
          percent: assessment.completed_at ? 1 : assessment.progress,
          completeDate: assessment.completed_at,
        },
      };
    });

    setDisplayAssessments(val);
    if (assessments.length > 0) {
      setLastAssessment(assessments[0]);
    }
  }, [assessments, showComplete, dispatch]);

  // Report table and buttonLabel
  useEffect(() => {
    if (!lastAssessment) return;

    if (lastAssessment) {
      //@ts-ignore
      if (lastAssessment.report_display?.student === true) {
        setButtonLabel("View My Report");

        // must turn into array for table to display
        const lastAssessmentArray = new Array(lastAssessment);

        const report = lastAssessmentArray.map((assessment) => {
          return {
            ...assessment,
            title: "Learner Profile",
            action: (
              <Button
                label={"View"}
                primary={true}
                size={ButtonSizes.tiny}
                type={ButtonTypes.ok}
                onClick={() =>
                  window.open(
                    `${RouteLink.Reports}/${assessment.subid}`,
                    "_blank"
                  )
                }
              />
            ),
          };
        });
        setDisplayReports(report);
      } else if (lastAssessment.progress > 0) {
        setButtonLabel("resume");
        setDisplayReports([]);
      } else {
        setButtonLabel("start");
        setDisplayReports([]);
      }
    }
  }, [lastAssessment]);

  // create an assessment if one doesn't exist
  useEffect(() => {
    if (assessments && assessments.length === 0 && userId) {
      dispatch(
        createAssessment({
          user_id: userId,
          type: assessments_type.MINDPRINT_SURVEY_V2,
        })
      );
    }
  }, [assessments, userId, dispatch]);

  function handleCloseFeedback(value: string) {
    if (value && value.length > 0) {
      dispatch(
        sendFeedback({
          feedback: value,
          assessmentId:
            lastAssessment?.formatted?.assessment_id || lastAssessment?.id,
        })
      ).then((result) => showToast(result));
    }
    navigate("/home");
  }

  function showToast(result: any) {
    if (result.error) {
      setErrorMessage(result.error.message);
      setShowErrorToast(true);
    } else {
      setShowSuccessToast(true);
    }
  }

  function handleToastDismiss() {
    setShowSuccessToast(false);
    setShowErrorToast(false);
    setErrorMessage("");
  }

  return (
    <>
      <Banner
        firstName={firstName}
        lastAssessment={lastAssessment}
        ctaClick={() => {
          if (lastAssessment?.hash) {
            dispatch(fetchOneAssessmentForHash(lastAssessment.hash));
          }
        }}
      />

      <LaunchAssessment
        url={assessmentUrl}
        handleClose={() => dispatch(clearAssessmentUrl())}
        buttonLabel={buttonLabel}
      />
      <CompleteAssessment
        show={showComplete}
        handleClose={(value) => {
          handleCloseFeedback(value);
        }}
      />

      <Toast
        message="Your feedback was successfully sent."
        show={showSuccessToast}
        type={ToastTypes.success}
        dismiss={() => handleToastDismiss()}
        dismissable={true}
      />
      <Toast
        message={`Oops! ${errorMessage}. Please contact your Admin.`}
        show={showErrorToast}
        type={ToastTypes.warning}
        dismiss={() => handleToastDismiss()}
        dismissable={true}
      />

      <Container>
        <h2 style={{ margin: "1em 0" }}>My Assessments</h2>
        <Divider />
        <Table
          theme={TableThemes.embedded}
          pageSize={3}
          headers={[
            {
              label: "Assigned",
              accessor: "created_at",
              type: CellTypes.date,
            },
            {
              label: "Progress",
              accessor: "progressView",
              type: CellTypes.progress,
            },
            {
              label: "",
              accessor: "action",
              type: CellTypes.element,
              maxWidth: 200,
            },
          ]}
          rows={displayAssessments}
        />
      </Container>
      <Container>
        <h2 style={{ margin: "1em 0" }}>My Reports</h2>
        <Divider />
        <Table
          theme={TableThemes.embedded}
          pageSize={3}
          headers={[
            {
              label: "Created",
              accessor: "created_at",
              type: CellTypes.date,
            },
            {
              label: "Report",
              accessor: "title",
              type: CellTypes.string,
            },
            {
              label: "",
              accessor: "action",
              type: CellTypes.element,
              maxWidth: 200,
            },
          ]}
          rows={displayReports}
        />
      </Container>
    </>
  );
};

export default Student;
